module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Beyond The Apprenticeship","short_name":"BTA","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/bta_logo_icon_b_square.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"cacheId":"bta-offline"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
